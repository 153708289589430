import React from "react";
import { PrimaryButton } from "./Buttons";
import HeroBackground from "./../assets/background/hero.jpg";

const HeroInformation = () => {
  return (
    <div className="flex justify-between flex-col lg:flex-row">
      <div className=" px-4 py-8 lg:px-20 lg:py-16">
        {/* <h3 className="text-secondary text-xl font-bold uppercase">investment</h3> */}
        <h1 className="text-primary text-5xl lg:text-7xl w-full lg:w-[40rem]">
          Empowering Local Businesses with Access to Finance.
        </h1>
        <p className="w-full lg:w-[40rem] mt-5 text-xl text-justify text-gray-600">
          We are a technology-enabled data-driven company that aims to support
          local businesses across different industries with access to finance in
          order to grow their revenue by over 50% annually.
        </p>
        <div className="mt-5">
          <PrimaryButton btnText={"Get Started"} width="w-64" />
        </div>
      </div>
      <div>
        <img src={HeroBackground} alt="Hero Background" />
      </div>
    </div>
  );
};

export default HeroInformation;
