export const SecondaryButton = ({ btnText, width }) => {
  return (
    <button
      className={`bg-gray-200 px-8 py-5 text-dark rounded-md disabled:opacity-50 ${
        width && width
      }`}
    >
      {btnText}
    </button>
  );
};

export const PrimaryButton = ({ btnText, width }) => {
  return (
    <button
      className={`bg-primary rounded-md px-8 py-5 text-white disabled:opacity-50 ${
        width && width
      }`}
    >
      {btnText}
    </button>
  );
};
