import React from "react";
import AboutInformation from "../components/AboutInformation";
import { PrimaryButton } from "../components/Buttons";
import Navbar from "../components/Navbar";
import OurPromise from "../components/OurPromise";
import OurValues from "../components/OurValues";
import CTA from "../components/CTA";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div>
      <div className="bg-light-warning h-full">
        <Navbar button={<PrimaryButton btnText="Get Started" />} />
        <AboutInformation />
      </div>
      <OurValues />
      <OurPromise />
      <CTA />
      <Footer />
    </div>
  );
};

export default About;
