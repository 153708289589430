import React from "react";
import SectionHeading from "./SectionHeading";
import InvestOne from "./../assets/icons/one.svg";
import InvestTwo from "./../assets/icons/two.svg";
import InvestThree from "./../assets/icons/three.svg";
import WhyInvestCard from "./WhyInvestCard";

const WhyInvest = () => {
  return (
    <div className="px-4 py-8 lg:px-20 lg:py-16">
      <SectionHeading
        captionColor={"text-secondary"}
        captionTitle="why invest"
        heading="We want you to trust us with your money. Here's why:"
      />
      <div className="flex justify-between flex-col md:flex-row gap-8 mt-16">
        <WhyInvestCard
          image={InvestOne}
          stepNumber="1"
          heading={"Discernible Impact"}
          body="Every penny raised is spent on making sure we multiply the revenue of
          small businesses by 10 annually. You'd see the value of your money in
          real-time."
        />
        <WhyInvestCard
          image={InvestTwo}
          stepNumber="2"
          heading={"Bi-weekly Updates"}
          body="We have a commitment to send you updates bi-weekly on our operations; particularly about how the local business owners who have benefited from our fund are okay."
        />
        <WhyInvestCard
          image={InvestThree}
          stepNumber="3"
          heading={"Licenses Covered"}
          body="Every penny raised is spent on making sure we multiply the revenue of
          small businesses by 10 annually. You'd see the value of your money in
          real-time."
        />
      </div>
    </div>
  );
};

export default WhyInvest;
