import React from "react";
import SalyRocket from "./../assets/background/SalyRocket.svg";
import { PrimaryButton } from "./Buttons";

const bg1 = {
  backgroundImage: `url(${SalyRocket})`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center`,
};

const OurPromise = () => {
  return (
    <div className="mt-32 pt-16 px-4 py-8 lg:px-20 lg:py-16 flex gap-12 bg-gray">
      <div className="h-[40rem] rounded-full w-1/2" style={bg1}></div>
      <div className="self-center w-1/2">
        <h3 className="text-warning text-xl font-bold uppercase">our goal</h3>
        <h1 className="text-primary text-5xl font-bold w-11/12 mt-5">
          We are very audacious (and excited) about our goal for local
          businesses.
        </h1>
        <p className="mt-5 text-xl">
          Our goal is to support 10,000 local businesses by 2027 with the
          finance, expertise and structure necessary to help them grow and scale
          their profit annually.
        </p>
        <div className="mt-10">
          <PrimaryButton btnText={"Get Started"} />
        </div>
      </div>
    </div>
  );
};

export default OurPromise;
