import React from "react";
import SectionHeading from "./SectionHeading";
import ValueCard from "./ValueCard";
import InnovationIcon from "./../assets/icons/val1.svg";
import CommitmentIcon from "./../assets/icons/val2.svg";
import TeamworkIcon from "./../assets/icons/val3.svg";
import GrowthIcon from "./../assets/icons/val4.svg";
import TransparencyIcon from "./../assets/icons/val5.svg";
import PassionIcon from "./../assets/icons/val6.svg";

const OurValues = () => {
  return (
    <div className="px-16 py-32">
      <SectionHeading
        captionColor={"text-warning"}
        captionTitle="our values"
        heading={"The values that drive everything we do at Octave. 💝"}
      />
      <div className="flex justify-between gap-8 mt-20 flex-wrap">
        <ValueCard
          icon={InnovationIcon}
          caption="Innovation"
          description={
            "At Octave, we strongly believe in Innovation as a major influence in the growth of the economy of our nation."
          }
        />
        <ValueCard
          icon={TeamworkIcon}
          caption="Teamwork"
          description={
            "At Octave, we strongly believe in Innovation as a major influence in the growth of the economy of our nation."
          }
        />
        <ValueCard
          icon={GrowthIcon}
          caption="Growth"
          description={
            "At Octave, we strongly believe in Innovation as a major influence in the growth of the economy of our nation."
          }
        />
        <ValueCard
          icon={PassionIcon}
          caption="Passion"
          description={
            "At Octave, we strongly believe in Innovation as a major influence in the growth of the economy of our nation."
          }
        />
        <ValueCard
          icon={TransparencyIcon}
          caption="Transparency"
          description={
            "At Octave, we strongly believe in Innovation as a major influence in the growth of the economy of our nation."
          }
        />
        <ValueCard
          icon={CommitmentIcon}
          caption="Commitment"
          description={
            "At Octave, we strongly believe in Innovation as a major influence in the growth of the economy of our nation."
          }
        />
      </div>
    </div>
  );
};

export default OurValues;
