import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./../assets/logo.svg";
import MailIcon from "./../assets/icons/mail.svg";
import PhoneIcon from "./../assets/icons/phone.svg";

const Footer = () => {
  return (
    <div className="bg-gray px-16 py-24">
      <div className="flex gap-8 justify-between">
        <div className="w-1/3">
          <img src={Logo} alt="Company Logo" />
          <p className="mt-5 text-justify">
            We are a technology-enabled data-driven company that aims to support
            local businesses across different industries with access to finance
            in order to grow their revenue by over 50% annually.
          </p>
        </div>
        <div className="w-1/3 ml-0 lg:ml-20">
          <h3>Quick Links</h3>
          <ul className="mt-8 flex gap-8">
            <li className="hover:text-primary">
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="hover:text-primary">
              <NavLink to="/about-us">About Us</NavLink>
            </li>
            <li className="hover:text-primary">
              <NavLink to="/contact-us">Contact Us</NavLink>
            </li>
          </ul>
        </div>
        <div className="w-1/3 text-lg">
          <div className="flex gap-5 bg-[#2e4567e3] hover:bg-primary text-white p-5">
            <img src={MailIcon} alt="Mail Icon" />
            <div className="self-center">
              <p className="font-cLight">Email us</p>
              <h4 className="font-bold">hello@octave.ng</h4>
            </div>
          </div>
          <div className="flex gap-5 bg-[#2e4567e3] hover:bg-primary text-white p-5 mt-5">
            <img src={PhoneIcon} alt="Mail Icon" />
            <div className="self-center">
              <p className="font-cLight">Call Us</p>
              <h4 className="font-bold">+234(0) 903 898 2188</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
