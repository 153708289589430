import React from "react";
import { PrimaryButton } from "../components/Buttons";
import ContactForm from "../components/ContactForm";
import ContactInformation from "../components/ContactInformation";
import Faqs from "../components/Faqs";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import WhyInvest from "../components/WhyInvest";

const Contact = () => {
  return (
    <div>
      <div className="bg-white h-full">
        <Navbar button={<PrimaryButton btnText="Get Started" />} />
        <ContactInformation />
        <ContactForm />
        <WhyInvest />
        <Faqs />
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
