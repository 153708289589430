import React from "react";

const UpdateCard = ({ icon, caption, description }) => {
  return (
    <div className=" bg-gray-50 rounded-lg p-16">
      <div className="mt-5">
        <img src={icon} alt="Report Icon" />
      </div>
      <h3 className="text-primary text-3xl font-bold mt-5"> {caption} </h3>
      <p className="mt-5 text-justify">{description}</p>
    </div>
  );
};

export default UpdateCard;
