import React from "react";

const FormWithLabel = ({ label, inputType, inputName, placeholder }) => {
  return (
    <div>
      <label className="text-primary font-bold text-lg">{label}</label>
      <div>
        <input
          type={inputType}
          name={inputName}
          className="p-6 focus:outline-none text-gray-700 focus:text-primary w-full bg-white  mt-2 shadow"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default FormWithLabel;
