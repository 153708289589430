import React from "react";

const ValueCard = ({ icon, caption, description }) => {
  return (
    <div className=" bg-gray-50 p-16 w-[30%] rounded-lg">
      <div className="mt-5">
        <img src={icon} alt="Report Icon" />
      </div>
      <h3 className="text-primary text-3xl font-bold mt-5"> {caption} </h3>
      <p className="mt-5 text-justify">{description}</p>
      <hr className="mt-10 w-24 border-2 bg-primary border-primary" />
    </div>
  );
};

export default ValueCard;
