import React from "react";
import { PrimaryButton } from "./Buttons";
import MarketWomanImg from "./../assets/background/marketwoman.jpg";
import BusinessmanImg from "./../assets/background/businessman.jpg";

const bg1 = {
  backgroundImage: `url(${MarketWomanImg})`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
};
const bg2 = {
  backgroundImage: `url(${BusinessmanImg})`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center`,
};

const AboutInformation = () => {
  return (
    <div>
      <div className="px-4 py-8 lg:px-20 lg:py-16">
        <h3 className="text-warning text-xl font-bold uppercase">about us</h3>
        <h1 className="text-primary text-7xl font-bold w-11/12 mt-5">
          We're on a mission to empower local businesses worldwide.
        </h1>
        <p className="w-8/12 mt-5 text-xl">
          We are a technology-enabled data-driven company that aims to support
          local businesses across different industries with access to finance in
          order to grow their revenue by over 50% annually.
        </p>
        <div className="mt-5">
          <PrimaryButton btnText={"Get Started"} width="w-64" />
        </div>
      </div>
      <div className="flex mt-10">
        <div className="h-[30rem] w-[17rem] bg-secondary rounded-br-full rounded-tr-full"></div>
        <div
          className="h-[30rem] w-[30rem] bg-secondary rounded-full"
          style={bg1}
        ></div>
        <div className="flex flex-col">
          <div className="h-[15rem] w-[15rem] bg-secondary rounded-tr-full"></div>
          <div className="h-[15rem] w-[15rem] bg-danger rounded-bl-full"></div>
        </div>
        <div
          className="h-[30rem] w-[15rem] bg-danger rounded-br-full rounded-tl-full"
          style={bg2}
        ></div>
        <div className="flex flex-col">
          <div className="h-[15rem] w-[15rem] bg-warning rounded-tr-full"></div>
          <div className="h-[15rem] w-[15rem] bg-secondary rounded-tr-full"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutInformation;
