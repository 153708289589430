import React from "react";
import { SecondaryButton } from "./Buttons";

const CTA = () => {
  return (
    <div className="bg-primary flex flex-col lg:flex-row mt-32">
      <div className="px-16 py-32">
        <div>
          <div className="w-full lg:w-4/5">
            <h1 className="text-white text-4xl font-extrabold text-justify">
              We work hard to make local business owners smile everyday. We want
              you to be a part of our success story.
            </h1>
            <div className="mt-8">
              <SecondaryButton
                btnText={"Get Started"}
                width="w-full md:w-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-3/4 lg:flex-wrap">
        <div className="h-1/2 w-1/2 bg-secondary rounded-bl-full"></div>
        <div className="h-1/2 w-1/2 bg-danger rounded-bl-full"></div>
        <div className="h-1/2 w-1/2 bg-success rounded-bl-full"></div>
        <div className="h-1/2 w-1/2 bg-warning rounded-bl-full"></div>
      </div>
    </div>
  );
};

export default CTA;
