import React from "react";
import HeroInformation from "../components/HeroInformation";
import Navbar from "../components/Navbar";
import OurSolution from "./../components/OurSolution";
import CTA from "./../components/CTA";
import WhyInvest from "./../components/WhyInvest";
import { PrimaryButton } from "./../components/Buttons";
import Faqs from "../components/Faqs";
import Footer from "../components/Footer";

const Hero = () => {
  return (
    <div>
      <div className="main-container">
        <Navbar button={<PrimaryButton btnText={"Download Pitch Deck"} />} />
        <HeroInformation />
      </div>
      <OurSolution />
      <CTA />
      <WhyInvest />
      <Faqs />
      <Footer />
    </div>
  );
};

export default Hero;
