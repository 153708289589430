import React from "react";

const ContactInformation = () => {
  return (
    <div>
      <div className="px-4 py-8 lg:px-20 lg:py-16 text-center">
        <h3 className="text-secondary text-xl font-bold uppercase">
          contact us
        </h3>
        <h1 className="text-primary text-7xl font-bold mt-5 tracking-wide">
          Get in touch today
        </h1>
        <p className="mt-5 text-xl m-auto">
          Empowering Local Businesses <br />
          Satisfying Investors.
        </p>
      </div>
    </div>
  );
};

export default ContactInformation;
