import React, { useState } from "react";

const FAQList = ({ question, answer }) => {
  const [showAccordion, setShowAccordion] = useState(false);

  return (
    <div
      className="bg-white w-full md:w-4/5 m-auto p-8 cursor-pointer"
      onClick={() => {
        setShowAccordion(!showAccordion);
      }}
    >
      <div className="flex justify-between">
        <h3 className="text-primary text-justify w-4/5 text-lg lg:text-xl">
          {" "}
          {question}{" "}
        </h3>
        <div className="self-center">
          {showAccordion ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-primary"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )}
        </div>
      </div>
      <p className={`mt-8 text-justify ${!showAccordion && `hidden`}`}>
        {answer}
      </p>
    </div>
  );
};

export default FAQList;
