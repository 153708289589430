import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "./../assets/logo.svg";

const Navbar = ({ button }) => {
  const [toggleNav, setToggleNav] = useState(false);

  const handleToggleNav = () => {
    setToggleNav(!toggleNav);
  };
  return (
    <Fragment>
      <div className="flex justify-between w-full px-4 py-6 lg:px-16 lg:py-10 shadow-lg lg:shadow-none">
        <div className="self-center">
          <img src={Logo} alt="Octave Logo" className="w-24" />
        </div>
        <div
          className="self-center block lg:hidden cursor-pointer"
          onClick={handleToggleNav}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="bi bi-list w-8 h-8"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </div>
        <div className="lg:flex gap-10 font-cRegular hidden">
          <div className="self-center">
            <NavLink to="/">Home</NavLink>
          </div>
          <div className="self-center">
            <NavLink to="/about-us">About Us</NavLink>
          </div>
          <div className="self-center">
            <NavLink to="/contact-us">Contact Us</NavLink>
          </div>
        </div>
        <div className="hidden lg:block">{button}</div>
      </div>
      {/* Mobile Nav */}
      <div className="p-4">
        <div
          className={`bg-gray-100 rounded lg:hidden ${
            toggleNav ? `block` : `hidden`
          }`}
        >
          <ul className="font-semibold">
            <li className="p-4">
              <NavLink to="/about-us">About Us</NavLink>
            </li>
            <hr />
            <li className="p-4">
              <NavLink to="/resources">Contact Us</NavLink>
            </li>
            <hr />
            <div className="p-4 block lg:hidden">{button}</div>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
