import React from "react";

const WhyInvestCard = ({ image, stepNumber, heading, body }) => {
  return (
    <div className="w-full myShadow">
      <div className="relative">
        <img src={image} alt="Invest One" className="w-full rounded-t-xl" />
        <div className="rounded-full w-32 h-32 bg-primary p-10 absolute bottom-[-4rem] left-[120px] lg:left-40 text-center text-4xl font-bold text-white">
          {stepNumber}
        </div>
      </div>
      <div className=" px-6 py-8 mt-12">
        <h3 className="text-3xl font-bold text-primary text-center">
          {heading}
        </h3>
        <p className="mt-5 text-justify px-2">{body}</p>
      </div>
    </div>
  );
};

export default WhyInvestCard;
