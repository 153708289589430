import React from "react";
import FAQList from "./FAQList";
import SectionHeading from "./SectionHeading";

const Faqs = () => {
  return (
    <div className="px-4 py-8 lg:px-20 lg:py-16 bg-primary mt-32">
      <SectionHeading
        captionColor={"text-danger"}
        captionTitle="general info"
        heading="Frequently Asked Questions"
        headingColor={"text-white"}
      />
      <div className="mt-10 flex flex-col gap-10">
        <FAQList
          question={"What's in it for me if you say I can gain a lot of money?"}
          answer="We promise you that this will be one of the best investments you will ever make. We assure you of value for money."
        />
        <FAQList
          question={"What's in it for me?"}
          answer="We promise you that this will be one of the best investments you will ever make. We assure you of value for money."
        />
        <FAQList
          question={"What's in it for me if you?"}
          answer="We promise you that this will be one of the best investments you will ever make. We assure you of value for money."
        />
        <FAQList
          question={"What's in it for me?"}
          answer="We promise you that this will be one of the best investments you will ever make. We assure you of value for money."
        />
      </div>
    </div>
  );
};

export default Faqs;
