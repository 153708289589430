import React from "react";
import { PrimaryButton } from "./Buttons";
import FormWithLabel from "./FormWithLabel";

const ContactForm = () => {
  return (
    <div className="bg-primary mt-32 px-8">
      <div className="flex translate-y-[-8rem]">
        <div className="w-1/4">
          <div className="flex flex-col">
            <div className="h-[23rem] w-[23rem] bg-success rounded-tr-full"></div>
            <div className="h-[23rem] w-[23rem] bg-secondary rounded-bl-full"></div>
          </div>
        </div>
        <div className="w-2/4 bg-white p-16 border border-gray shadow-2xl">
          <div className="flex gap-8 flex-wrap">
            <div className="w-[46%]">
              <FormWithLabel
                label={"Name"}
                inputType="text"
                inputName={"name"}
                placeholder="Enter your Name"
              />
            </div>
            <div className="w-[46%]">
              <FormWithLabel
                label={"Email"}
                inputType="email"
                inputName={"email"}
                placeholder="Enter your Email"
              />
            </div>
            <div className="w-[46%]">
              <FormWithLabel
                label={"Phone Number"}
                inputType="text"
                inputName={"phone"}
                placeholder="Enter your Phone Number"
              />
            </div>
            <div className="w-[46%]">
              <FormWithLabel
                label={"Subject"}
                inputType="text"
                inputName={"subject"}
                placeholder="Enter the Subject"
              />
            </div>
            <div className="w-full">
              <div>
                <label className="text-primary font-bold text-lg">
                  How can we help?
                </label>
                <textarea
                  name={"message"}
                  cols="30"
                  rows="5"
                  className="p-6 focus:outline-none text-gray-700 focus:text-primary w-full bg-white shadow mt-2 resize-none"
                  placeholder="Please, type your message here"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <PrimaryButton btnText={"Send Message"} />
          </div>
        </div>
        <div className="w-1/4">
          <div className="flex flex-col">
            <div className="h-[23rem] w-[23rem] bg-secondary rounded-tr-full"></div>
            <div className="h-[23rem] w-[23rem] bg-warning rounded-tr-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
