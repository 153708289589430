import React from "react";
import SectionHeading from "./SectionHeading";
import UpdateCard from "./UpdateCard";
import UpdateIcon from "./../assets/icons/update.svg";
import ReportIcon from "./../assets/icons/report.svg";
import SupportIcon from "./../assets/icons/support.svg";

const OurSolution = () => {
  return (
    <div className="px-4 py-8 lg:px-20 lg:py-16">
      <SectionHeading
        captionColor={"text-warning"}
        captionTitle="features"
        heading={"our startup is a dream come true to local business owners"}
      />
      <div className="flex justify-between flex-col md:flex-row gap-8 mt-20">
        <UpdateCard
          icon={UpdateIcon}
          caption="Periodic Updates"
          description={
            "We are committed to keeping you fully informed of every local businesse that receives adequate finance from our company."
          }
        />
        <UpdateCard
          icon={ReportIcon}
          caption="Earnings Report"
          description={
            "We are committed to keeping you fully informed of every local businesse that receives adequate finance from our company."
          }
        />
        <UpdateCard
          icon={SupportIcon}
          caption="Fully Incorporated"
          description={
            "We are committed to keeping you fully informed of every local businesse that receives adequate finance from our company."
          }
        />
      </div>
    </div>
  );
};

export default OurSolution;
