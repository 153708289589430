import React from "react";

const SectionHeading = ({
  captionTitle,
  captionColor,
  heading,
  headingColor,
}) => {
  return (
    <div className="text-center pt-32">
      <h4 className={`text-xl ${captionColor} uppercase font-bold`}>
        {captionTitle}
      </h4>
      <h1
        className={`${
          headingColor ? headingColor : `text-primary`
        } text-3xl md:text-4xl lg:text-5xl capitalize font-extrabold pt-4 w-full lg:w-3/5 lg:m-auto`}
      >
        {heading}
      </h1>
    </div>
  );
};

export default SectionHeading;
